import { Injectable } from '@angular/core';
import { SubmittalRequestDetail } from 'app/main/pages/dashboard/scrumboard/project.model';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject } from 'rxjs';
import { StakeholderProjectDetail } from './models/stakeholder-project';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class StakeholderProjectsService {

  apiUrl = environment.basePath + '/submittals';
  pageSize = 30;
  fileUploadLimit = 10;

  private onSHProjectChange = new BehaviorSubject<StakeholderProjectDetail | null>(null);
  public projectDetail = this.onSHProjectChange.asObservable();

  private onExitSHDashboard = new BehaviorSubject(null);
  public removeSHNavigation = this.onExitSHDashboard.asObservable();

  ownerNav = {
    id: "owner",
    title: "Owner",
    type: "group",
    children: [
      {
        id: "owner-dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId,
      },
      {
        id: "updates",
        title: "Project Updates",
        type: "item",
        icon: "updates",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId + "/updates"
      },
      {
        id: "pcr",
        title: "PCR",
        type: "item",
        icon: "pcr",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId + "/submittal/requests",
      },
      {
        id: "rfi",
        title: "RFI",
        type: "item",
        icon: "rfi",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId + "/submittal/requests"
      },
      {
        id: "submittal",
        title: "Submittals",
        type: "item",
        icon: "submittals",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId + "/submittal/requests"
      },
      {
        id: "shchat",
        title: "Chat",
        type: "item",
        icon: "custom-chat",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId + "/chat"
      },
      {
        id: "billing",
        title: "Billing",
        type: "item",
        icon: "finance",
        url: ""
        // url: "/stakeholder-users/projects/project/" + this.projectId + "/submittal/requests"
      },
    ]
  };

  architectNav = {
    id: "architect",
    title: "Architect",
    type: "group",
    children: [
      {
        id: "architect-dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: "",
      },
      {
        id: "pcr",
        title: "PCR",
        type: "item",
        icon: "pcr",
        url: "",
      },
      {
        id: "rfi",
        title: "RFI",
        type: "item",
        icon: "rfi",
        url: ""
      },
      {
        id: "submittal",
        title: "Submittals",
        type: "item",
        icon: "submittals",
        url: ""
      }
    ]
  }


  constructor(
    private baseService: BaseService,
    private _fuseNavigationService: FuseNavigationService
  ) { }

  getStakeholderProjects(sortByName: boolean, pageNo: number, searchString) {
    let api = "/users/projects-list?";
    if (sortByName)
      api = api + "sort=name&";
    return this.baseService.get(environment.basePath + api + `pageNumber=${pageNo}&pageSize=${this.pageSize}&name=${searchString}`, true,);
  }

  getStakeholderProjectById(byId) {
    return this.baseService.get(environment.basePath + "/projects/" + byId, true);
  }

  setProject(data: any) {
    this.onSHProjectChange.next(data);
  }

  setNavigation(projectId, role) {
    let current: Array<any> = this._fuseNavigationService.getCurrentNavigation();
    if (current.length > 1) this._fuseNavigationService.removeNavigationItem(current[1].id);

    let navitem = null;
    if (role == "owner") {
      this.ownerNav.children[0].url = "/stakeholder-users/projects/project/" + projectId;
      this.ownerNav.children[1].url = "/stakeholder-users/projects/project/" + projectId + "/updates";
      // this.ownerNav.children[2].url = "/stakeholder-users/projects/project/" + projectId + "/upcoming-tasks";
      this.ownerNav.children[2].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
      this.ownerNav.children[3].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
      this.ownerNav.children[4].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
      this.ownerNav.children[5].url = "/stakeholder-users/projects/project/" + projectId + "/chat";
      this.ownerNav.children[6].url = "/stakeholder-users/projects/project/" + projectId + "/chat";
      navitem = this.ownerNav;
    }
    else {
      this.architectNav.children[0].url = "/stakeholder-users/projects/project/" + projectId;
      this.architectNav.children[1].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
      this.architectNav.children[2].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
      this.architectNav.children[3].url = "/stakeholder-users/projects/project/" + projectId + "/submittal/requests";
      navitem = this.architectNav;
    }

    // this.navigation = navStakeholder;
    this._fuseNavigationService.addNavigationItem(navitem, "end");
    // this._fuseNavigationService.unregister('main');
    // this._fuseNavigationService.register('main', this.navigation);
    this._fuseNavigationService.setCurrentNavigation('main');
  }

  exitNavigation() {
    this.onExitSHDashboard.next('remove');
  }

  getReceivedSubmittals(projectid, currentPage, srchKey: string, status: string, sortOrder: number) {
    return this.baseService.get(this.apiUrl + `/received/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&search=${srchKey}&status=${status}${sortOrder == -1 ? '&sort=-1' : ''}`, true);
  }

  getSubmittalDetailById(id: string) {
    return this.baseService.get(this.apiUrl + '/' + id, true);
  }

  getFileExtension(fileType: string) {
    if (fileType.includes('image/bmp') || fileType.includes('image/gif') || fileType.includes('image/jpeg') || fileType.includes('image/tiff') || fileType.includes('image/png')) return 'image';
    else if (fileType.includes('text/plain') || fileType.includes('rtf') || fileType.includes('wordprocessingml') || fileType.includes('msword') || fileType.includes('opendocument.text')) return 'doc';
    else if (fileType.includes('pdf')) return 'pdf';
    else if (fileType.includes('ms-excel') || fileType.includes('spreadsheetml') || fileType.includes('spreadsheet') || fileType.includes('csv')) return 'xls';
    else if (fileType.includes('ms-powerpoint') || fileType.includes('presentation')) return 'gd-ppt';
    else if (fileType.includes('video')) return 'db-video';
    else if (fileType.includes('acad') || fileType.includes('dwg')) return 'dwg';
    else if (fileType.includes('dxf')) return 'dxf';
    else return 'general';
  }

  getSubmittalComments(id, currentPage: number) {
    return this.baseService.get(this.apiUrl + `/comments/${id}?pageNumber=${currentPage}&pageSize=30`, true);
  }

  addSubmittalComment(data, id) {
    return this.baseService.patch(this.apiUrl + '/add-comment/' + id, true, data);
  }

  createReviewerFlow(submittal: SubmittalRequestDetail): SubmittalRequestDetail {
    submittal.reqFlow = [];
    submittal.reqFlow.push({
      id: submittal.issuer._id._id,
      name: submittal.issuer._id.name,
      status: submittal.issuer.status,
      img: submittal.issuer._id.img,
      role: 'Sub-Contractor',
      serial: -1,
      email: submittal.issuer._id.email,
      location: submittal.issuer._id.location,
      phone: submittal.issuer._id.phone
    });

    submittal.reqFlow.push({
      id: submittal.owner._id._id,
      name: submittal.owner._id.name,
      status: submittal.owner.status,
      img: submittal.owner._id.img,
      role: 'General Contractor',
      serial: 0,
      email: submittal.owner._id.email,
      location: submittal.owner._id.location,
      phone: submittal.owner._id.phone
    })

    submittal.respondents.forEach(obj => {
      submittal.reqFlow.push({
        id: obj._id._id,
        name: obj._id.name,
        status: obj.status,
        img: obj._id.img,
        role: obj.role,
        serial: obj.serialNumber,
        email: obj._id.email,
        location: obj._id.location,
        phone: obj._id.phone
      });
    });

    return submittal;
  }
}
